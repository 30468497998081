import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeWrapperProvider } from '@banksea-finance/ui-kit'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(37, 58, 112)',
      light: 'rgb(62,91,164)',
      dark: 'rgb(23,37,73)',
      contrastText: '#fff'
    },
    background: {
      paper: 'rgb(12,13,33)'
    }
  },
  typography: {
    fontFamily: 'Kanit'
  },
})

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <ThemeWrapperProvider
      configOverride={{
        fontFamilies: {
          common: 'Kanit, Helvetica, Arial, sans-serif'
        }
      }}
    >
      <ThemeProvider theme={darkTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </ThemeWrapperProvider>
  </React.StrictMode>
)
