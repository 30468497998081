import React, { useMemo, useState } from 'react'
import { Box, Image, Text, TextProps } from '@banksea-finance/ui-kit'
import { SolanaMarkSvg } from '@/components/svgs'
// @ts-ignore
import Tooltip from 'rc-tooltip'
import { SupportedChains } from '@/hooks/queries/types'
import 'rc-tooltip/assets/bootstrap.css'

export interface PriceLabelProps extends TextProps {
  value?: string | number
  chain?: SupportedChains
  iconSize?: string
}

export const PriceLabel: React.FC<PriceLabelProps> = ({
  chain = 'Solana',
  iconSize = '14px',
  value,
  ...textProps
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const formattedValue = useMemo(() => {
    return value ? (
      typeof value === 'number'
        ? value.toFixed(2)
        : parseFloat(value).toFixed(2)
    ) : '-'
  }, [value])

  return (
    <Tooltip
      visible={tooltipVisible}
      animation={'zoom'}
      placement={'top'}
      trigger={['hover']}
      overlay={
        <Text>
          {formattedValue} {chain ==='Solana' ? 'SOL' : 'ETH'}
        </Text>
      }
    >
      <Box
        display={'inline-flex'}
        style={{ alignItems: 'center' }}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        {
          value && chain
            ? (
              chain === 'Solana'
                ? <SolanaMarkSvg height={iconSize} />
                : (
                  <Image
                    src={'https://ethereum.org/static/a183661dd70e0e5c70689a0ec95ef0ba/13c43/eth-diamond-purple.png'}
                    height={iconSize}
                  />
                )
            )
            : (<></>)
        }
        <Text ml={'4px'} lineHeight={1} {...textProps}>
          {formattedValue}
        </Text>
      </Box>
    </Tooltip>
  )
}
