import { styled } from '@mui/material'

export const StatisticItemBorder = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px rgb(49, 64, 103) solid;
  border-radius: .5rem;
  background-color: rgb(12, 13, 33);
  position: relative;
  overflow: hidden;
  height: 100%;

  .data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0 10px 10px;
  }

  .item-icon {
    width: 25%;
    height: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }
`
