import { SupportedChains } from '@/hooks/queries/types'
// @ts-ignore
import numberAbbreviate from 'number-abbreviate'

export const abbrAddress = (address?: string, length = 6) => {
  return address ? `${address.substring(0, length)}...${address.slice(-length)}` : '-'
}

export const abbrNumber = (value?: number | string, decPlaces = 2) => value ? numberAbbreviate(value, decPlaces) : undefined

export const priceUnitByChain: Record<SupportedChains, string> = {
  'Ethereum': 'ETH',
  'Solana': 'SOL'
}

export const imageProxyUrl = (url?: string, opts?: { width?: number, height?: number }) => {
  const {
    width = 400,
    height = 400
  } = opts || {}

  return url ? `https://img-cdn.banksea.finance/rs:fill:${width}:${height}:0:0/plain/${url}` : undefined
}
