import { useQueryTokenBaseInfo } from '@/hooks/queries/useQueryTokenBaseInfo'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box as MuiBox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import dayjs from 'dayjs'
import { Box, Flex, Grid, Image, Text } from '@banksea-finance/ui-kit'
import { useQueryTokenActivities } from '@/hooks/queries/useQueryTokenActivities'
import { grey } from '@mui/material/colors'
import { abbrAddress, imageProxyUrl } from '@/utils'
import { HighlightTitle, NFTName } from '@/pages/collection/index.style'
import { StatisticItemBorder } from '@/pages/token/index.style'
import { EChartsOption } from 'echarts'
import ReactECharts from 'echarts-for-react'
import { useLocationQuery } from '@/hooks/useLocationQuery'
import { SupportedChains } from '@/hooks/queries/types'
import { PriceLabel, PriceLabelProps } from '@/components/price-label'
import { AuctionSvg, SaleSvg, ValuationSvg } from '@/components/svgs'

type StatisticItemProps = {
  value: PriceLabelProps['value']
  chain: PriceLabelProps['chain']
  label: string
  time?: number
  icon: React.ReactNode
}

const toStartTimestampOfDay = (timestamp: number) => {
  const ONE_DAY = 1000 * 60 * 60 * 24

  return timestamp - timestamp % ONE_DAY
}

const StatisticItem: React.FC<StatisticItemProps> = ({ label, time, value, chain, icon }) => {
  const theme = useTheme()

  return (
    <StatisticItemBorder>
      <div className={'data'}>
        <Text color={theme.palette.text.disabled} fontSize={'14px'}>
          {label}
        </Text>

        <PriceLabel
          value={value}
          chain={chain}
          fontSize={'24px'}
          iconSize={'18px'}
          my={'8px'}
        />

        <Text color={theme.palette.text.disabled} fontSize={'12px'}>
          {time ? dayjs(time).format('MM/DD hh:mm') : '-'}
        </Text>
      </div>
      <div className={'item-icon'}>
        {icon}
      </div>
    </StatisticItemBorder>
  )
}

const ActivitiesSection: React.FC = () => {
  const chain = useLocationQuery<SupportedChains>('chain')!

  const { tokenAddress } = useParams()
  const { data: activities } = useQueryTokenActivities(tokenAddress)

  const [current, setCurrent] = useState(0)
  const [size, setSize] = useState(10)

  return (
    <Box as={'section'}>
      <HighlightTitle>
        Activities
      </HighlightTitle>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ '& > th': { color: grey['500'] } }}>
              <TableCell>TX Type</TableCell>
              <TableCell align="center">Buyer</TableCell>
              <TableCell align="center">Seller</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="right">TX Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities?.activities?.slice(current * size, (current + 1) * size)?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ':hover': { cursor: 'pointer' }
                }}
              >
                <TableCell>{row.transactionType}</TableCell>
                <TableCell align="center">{abbrAddress(row.buyer, 4)}</TableCell>
                <TableCell align="center">{abbrAddress(row.seller)}</TableCell>
                <TableCell align="center">
                  <PriceLabel value={row.totalAmount?.toFixed(2)} chain={chain} />
                </TableCell>
                <TableCell align="right">{dayjs(row.transactionTime).format('YY/MM/DD hh:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={activities?.activities?.length || 0}
        rowsPerPage={size}
        rowsPerPageOptions={[10]}
        page={current}
        onPageChange={(event, page) => setCurrent(page)}
        onRowsPerPageChange={event => setSize(+event.target.value)}
      />
    </Box>
  )
}

export const TokenPage: React.FC = () => {
  const chain = useLocationQuery<SupportedChains>('chain')

  if (!chain) return (
    <>
      No chain type specified.
    </>
  )

  const { tokenAddress } = useParams()
  const { data: baseInfo } = useQueryTokenBaseInfo(tokenAddress)
  const { data: activities } = useQueryTokenActivities(tokenAddress)
  const navigate = useNavigate()

  const options: EChartsOption = useMemo(() => {
    return {
      xAxis: {
        name: 'Time',
        type: 'time',
      },
      yAxis: {
        type: 'value',
        name: 'Price'
      },
      color: [
        'rgb(55,107,255)', 'rgb(131,212,236)',
        '#2b9357', '#ec9c83'
      ],
      tooltip: {
        trigger: 'axis',
        hideDelay: 250,
        backgroundColor: 'rgb(5,0,23)',
        position: point => point,
        textStyle: {
          color: '#ddd'
        }
      },
      series: [
        {
          type: 'scatter',
          name: 'Listing',
          data: activities?.listings?.map(o => ([toStartTimestampOfDay(o.transactionTime), o.totalAmount])) || []
        },
        {
          type: 'scatter',
          name: 'Sale',
          data: activities?.sales?.map(o => ([toStartTimestampOfDay(o.transactionTime), o.totalAmount])) || []
        },
        {
          type: 'scatter',
          name: 'Bid',
          data: activities?.bids?.map(o => ([toStartTimestampOfDay(o.transactionTime), o.totalAmount])) || []
        },
        {
          type: 'line',
          name: 'Valuation',
          data: activities?.valuations?.map(o => ([toStartTimestampOfDay(o.timestamp * 1000), o.valuation.toFixed(4)])) || []
        },
      ],
      legend: {
        data: ['Listing', 'Sale', 'Bid', 'Valuation'],
        textStyle: {
          color: '#ccc'
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
      ],
    }
  }, [activities])

  const chartRef = useRef<any>()

  useEffect(() => {
    const onresize = () => chartRef.current?.resize()

    window.addEventListener('resize', onresize)

    return () => {
      window.removeEventListener('resize', onresize)
    }
  }, [chartRef])

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'}>
      <Flex alignItems={'center'} mb={'48px'} gap={'8px'}>
        <MuiBox onClick={() => navigate(-1)} sx={{ ':hover': { cursor: 'pointer' } }}>
          <ArrowBackIcon />
        </MuiBox>
        <NFTName>
          {baseInfo?.token_name}
        </NFTName>
      </Flex>

      <Grid width={'100%'} gridTemplateColumns={'1fr 1.5fr'} gap={'64px'}>
        <Image
          src={imageProxyUrl(baseInfo?.image_url)}
          width={'100%'}
          borderRadius={'20px'}
          placeholderWidth={'calc((min(1440px, 80vw) - 64px) / 2.5)'}
          placeholderHeight={'calc((min(1440px, 80vw) - 64px) / 2.5)'}
        />

        <Box as={'section'}>
          <Grid gridTemplateColumns={'repeat(3, 1fr)'} mb={'32px'} height={'fit-content'} gap={'14px'}>
            <StatisticItem
              label={'Valuation'}
              time={activities?.valuations?.[0] ? activities?.valuations?.[0]?.timestamp * 1000 : undefined}
              value={activities?.valuations?.filter(o => o.valuation)?.[0]?.valuation?.toFixed(2)}
              chain={chain}
              icon={<ValuationSvg />}
            />

            <StatisticItem
              label={'Last Sale'}
              time={activities?.sales?.[0]?.transactionTime}
              value={activities?.sales?.[0]?.totalAmount?.toFixed(2)}
              chain={chain}
              icon={<SaleSvg />}
            />

            <StatisticItem
              label={'Last Listing'}
              time={activities?.listings?.[0]?.transactionTime}
              value={activities?.listings?.[0]?.totalAmount?.toFixed(2)}
              chain={chain}
              icon={<AuctionSvg />}
            />
          </Grid>

          <HighlightTitle>
            Valuation Analysis
          </HighlightTitle>

          <ReactECharts
            ref={ref => chartRef.current = ref}
            option={options}
            opts={{
              height: 400,
            }}
          />
        </Box>

        <Box as={'section'}>
          <HighlightTitle>
            Attributes
          </HighlightTitle>

          <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'8px'}>
            {baseInfo?.attributes?.map(attr => (
              <Flex
                key={attr.trait_type}
                flexDirection={'column'}
                ai={'center'}
                background={'rgb(12, 13, 33)'}
                jc={'space-between'}
                borderRadius={'10px'}
                py={'8px'}
              >
                <Text color={'disabled'} fontSize={'14px'}>
                  {attr.trait_type}
                </Text>
                <Text color={'#eee'} fontSize={'16px'}>
                  {attr.value}
                </Text>
                <Text color={'rgb(143,177,246)'} fontSize={'14px'}>
                  Rarity: {attr.rarity}%
                </Text>
              </Flex>
            ))}
          </Grid>
        </Box>

        <ActivitiesSection />
      </Grid>
    </Flex>
  )
}
