import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box as MuiBox,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useQueryCollectionActivities } from '@/hooks/queries/useQueryCollectionActivities'
import { useQueryCollectionDetail } from '@/hooks/queries/useQueryCollectionDetail'
import ReactECharts from 'echarts-for-react'
// @ts-ignore
import numberAbbreviate from 'number-abbreviate'
import { Box, Flex, Grid, Image, Skeleton, Text } from '@banksea-finance/ui-kit'
import _ from 'lodash'
import dayjs from 'dayjs'
import { abbrAddress, imageProxyUrl } from '@/utils'
import { DataGridItem, HighlightTitle, NFTName, SearchWrapper, StyledTable } from '@/pages/collection/index.style'
import { SearchRounded } from '@mui/icons-material'
import { EChartsOption } from 'echarts'
import { useQueryCollectionValuation } from '@/hooks/queries/useQueryCollectionValuation'
import { useLocationQuery } from '@/hooks/useLocationQuery'
import { SupportedChains } from '@/hooks/queries/types'
import { PriceLabel, PriceLabelProps } from '@/components/price-label'

const StatisticItem: React.FC<{ label: string, isPrice?: boolean } & PriceLabelProps> = ({ label, value, chain, isPrice = false }) => {
  const textProps: any = {
    fontSize: 'max(min(28px, 1.2vw), 16px)',
    textAlign: 'start',
    fontWeight: 'bold'
  }

  return (
    <DataGridItem>
      <Box mb={'4px'}>
        {
          isPrice ? (
            <PriceLabel
              value={value}
              chain={chain}
              iconSize={'min(20px, .85vw)'}
              {...textProps}
            />
          ) : (
            <Text {...textProps}>{value}</Text>
          )
        }
      </Box>
      <Typography textAlign={'start'} sx={{ color: grey['500'], fontSize: '14px' }}>
        {label}
      </Typography>
    </DataGridItem>
  )
}

const ActiveItemsSection: React.FC = () => {
  const navigate = useNavigate()
  const { collectionId } = useParams()
  const chain = useLocationQuery<SupportedChains>('chain')!
  const searchParams = new URLSearchParams(useLocation().search)

  const inputRef = useRef<HTMLInputElement>()
  const [total, setTotal] = useState(0)
  const [search, _setSearch] = useState('')
  const [current, setCurrent] = useState(+(searchParams.get('page') || 1))

  const setSearch = (val: string) => {
    _setSearch(prev => {
      if (prev !== val) setCurrent(1)

      return val
    })
  }

  const { data: activities } = useQueryCollectionActivities({ collectionId: collectionId!, search, chain, current })

  useEffect(() => {
    activities && setTotal(activities.total)
  }, [activities, total])

  useEffect(() => {
    searchParams.set('page', current.toString())
    navigate(`?${searchParams.toString()}`, { replace: true })
  }, [current])

  useEffect(() => {
    setSearch('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [chain, inputRef])

  return (
    <Fragment>
      <Flex justifyContent={'space-between'} width={'100%'} mb={'16px'} ai={'center'}>
        <HighlightTitle>Active Items</HighlightTitle>

        <SearchWrapper>
          <input
            ref={ref => (inputRef.current as any) = ref}
            className={'search-input'}
            placeholder={'Searching by token name'}
            onChange={_.debounce(({ target }) => setSearch(target.value), 500, { trailing: true })}
          />
          <SearchRounded />
        </SearchWrapper>
      </Flex>

      <TableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow sx={{ '& > th': { color: grey['500'] } }}>
              <TableCell>Item</TableCell>

              <TableCell>
                Last Listing Price
              </TableCell>
              <TableCell>Last Listing Time</TableCell>

              <TableCell>
                Last Sale Price
              </TableCell>
              <TableCell>Last Sale Time</TableCell>

              <TableCell>Valuation</TableCell>
              <TableCell align="right">Current Holder</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities ? (
              activities.records.filter(o => o.imageUrl && o.metaUriName).map(row => (
                <TableRow
                  key={row.metaUriName}
                  onClick={() => navigate(`/token/${row.tokenAddress}?chain=${chain}`)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ':hover': { cursor: 'pointer' }
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Flex alignItems={'center'}>
                      <Image
                        src={imageProxyUrl(row.imageUrl, { width: 40, height: 40 })}
                        width={'42px'}
                        height={'42px'}
                        borderRadius={'.4rem'}
                        mr={'8px'}
                      />
                      <span>{row.metaUriName}</span>
                    </Flex>
                  </TableCell>

                  <TableCell>
                    <PriceLabel chain={chain} value={row.listingPrice} />
                  </TableCell>
                  <TableCell>
                    {row.listingTime ? dayjs(row.listingTime * 1000).format('YY/MM/DD HH:mm:ss') : '-'}
                  </TableCell>

                  <TableCell>
                    <PriceLabel chain={chain} value={row.transactionPrice} />
                  </TableCell>
                  <TableCell>
                    {row.transactionTime ? dayjs(row.transactionTime * 1000).format('YY/MM/DD HH:mm:ss') : '-'}
                  </TableCell>

                  <TableCell>
                    <PriceLabel chain={chain} value={row.valuation} />
                  </TableCell>

                  <TableCell align="right">{abbrAddress(row.holder, 6)}</TableCell>
                </TableRow>
              ))
            ) : (
              _.range(10).map((o, i) => (
                <TableRow
                  key={`placeholder-${i}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ':hover': { cursor: 'pointer' }
                  }}
                >
                  {
                    _.range(7).map((_o, j) => (
                      <TableCell key={j}>
                        <Skeleton width={'100%'} height={'42px'} borderRadius={'21px'} />
                      </TableCell>
                    ))
                  }
                </TableRow>
              ) )
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>

      <Flex jc={'flex-end'} width={'100%'}>
        <TablePagination
          showFirstButton
          showLastButton
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={current - 1}
          onPageChange={(event, page) => {
            setCurrent(page + 1)
          }}
        />
      </Flex>
    </Fragment>
  )
}

const ValuationAnalysisSection: React.FC = () => {
  const { collectionId } = useParams()
  const chain = useLocationQuery<SupportedChains>('chain')!

  const { data: valuations } = useQueryCollectionValuation({ collectionId, chain })
  const chartRef = useRef<any>()

  const maxSalePrice = useMemo(() => {
    return valuations
      ?.filter(o => o.aiValuation)
      ?.map(o => o.totalAmount)
      .reduce((max, next) => Math.max(max, next), 0)
      || 0
  }, [valuations])

  const linearData = useMemo(() => {
    return new Array(100)
      .fill(undefined)
      .map((_, i) => (
        [
          (i / 100 * maxSalePrice).toFixed(3),
          (i / 100 * maxSalePrice).toFixed(2)
        ] as [string, string]
      ))
  }, [maxSalePrice])

  const options: EChartsOption = useMemo(() => {
    return {
      grid: {
        right: 96,
      },
      xAxis: {
        type: 'value',
        name: 'Sale Price'
      },
      yAxis: {
        type: 'value',
        name: 'AI Valuation'
      },
      color: ['rgb(39,65,145)', 'rgb(131,212,236)'],
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        backgroundColor: 'rgb(5,0,23)',
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const { totalAmount, aiValuation, nftName, timestamp } = props.data || {}

            const deviation = (() => {
              if (!totalAmount) return undefined

              if (aiValuation) return ((aiValuation - totalAmount) / totalAmount * 100).toFixed(2)

              return undefined
            })()

            return `
              <div style="display: flex; flex-direction: column; align-items: start; background: rgb(5,0,23); color: #ccdadd">
                <b style="font-size: 18px">${nftName}</b>
                <br>

                <div><b>Transaction Price: </b>${totalAmount}</div>
                <div><b>AI Model Valuation: </b>${aiValuation?.toFixed(3)}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${deviation ? (+deviation < 0 ? '#00ff00' : '#ff0000') : '#fff'}">
                    ${deviation ? `${deviation}%` : '-'}
                  </span>
                </div>
                <div>
                  <b>Transaction Time: </b>
                  ${dayjs(timestamp * 1000).format('YYYY/MM/DD HH:mm:ss')}
                </div>
              </div>
            `
            // <a href="https://explorer.solana.com/tx/${data.transactionSignature}" target="_blank" rel="noreferrer" style="color: #61dafb; text-decoration: none; margin-top: 8px;">View transaction on Solscan</a>
          }

          return ''
        }
      },
      dataset: [
        {
          source: (valuations || []).filter(o => o.aiValuation) as any
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData,
          symbol: 'none'
        },
        {
          type: 'scatter',
          name: 'Valuations',
          datasetIndex: 0,
          encode: {
            x: 'totalAmount',
            y: 'aiValuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuations'],
        textStyle: {
          color: '#ccc'
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
      ],
    }
  }, [valuations, linearData])

  useEffect(() => {
    const onresize = () => chartRef.current?.resize()

    window.addEventListener('resize', onresize)

    return () => {
      window.removeEventListener('resize', onresize)
    }
  }, [chartRef])

  return (
    <Box as={'section'}>
      <HighlightTitle>Valuation Analysis</HighlightTitle>

      <ReactECharts
        ref={ref => chartRef.current = ref}
        option={options}
        opts={{
          height: 400,
        }}
      />
    </Box>
  )
}

const StatisticSection: React.FC = () => {
  const { collectionId } = useParams()
  const chain = useLocationQuery<SupportedChains>('chain')!
  const { data: collectionDetail } = useQueryCollectionDetail({ collectionId, chain })

  return (
    <Box as={'section'}>
      <HighlightTitle>Statistics</HighlightTitle>

      <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'16px'}>
        <StatisticItem label={'Market Cap'} value={collectionDetail?.marketCap} isPrice />
        <StatisticItem label={'Volume(24h)'} value={collectionDetail?.volume} isPrice />
        <StatisticItem label={'Floor Price'} value={collectionDetail?.floorPrice} isPrice />
        <StatisticItem label={'AI Floor Price'} value={collectionDetail?.aiFloorPrice} isPrice />
        <StatisticItem
          label={'Holder'}
          value={collectionDetail?.holder?.toLocaleString() || '-'}
        />
        <StatisticItem
          label={'Accuracy'}
          value={collectionDetail?.accuracy ? `${(collectionDetail.accuracy * 100).toFixed(2)}%` : '-'}
        />
      </Grid>
    </Box>
  )
}

export const CollectionPage: React.FC = () => {
  const chain = useLocationQuery<SupportedChains>('chain')

  if (!chain) return (
    <>
      No chain type specified.
    </>
  )

  const navigate = useNavigate()
  const { collectionId } = useParams()
  const { data: collectionDetail } = useQueryCollectionDetail({ collectionId, chain })

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'} width={'100%'}>
      <Flex alignItems={'center'} mb={'48px'} width={'100%'}>
        <MuiBox mr={'28px'} onClick={() => navigate(-1)} sx={{ ':hover': { cursor: 'pointer' } }}>
          <ArrowBackIcon />
        </MuiBox>
        <Image
          src={imageProxyUrl(collectionDetail?.image_url, { width: 64, height: 64 })}
          width={'48px'}
          height={'48px'}
          mx={'8px'}
          borderRadius={'8px'}
        />
        <NFTName>{collectionDetail?.name}</NFTName>
      </Flex>

      <Grid
        width={'100%'}
        gridTemplateColumns={{ xl: '1fr 1fr', _: '1fr' }}
        mb={'64px'}
        height={{ _: 'fit-content' }}
        gap={'5%'}
      >
        <StatisticSection />

        <ValuationAnalysisSection />
      </Grid>

      <ActiveItemsSection />
    </Flex>
  )
}
