import { useQuery } from '@tanstack/react-query'
import { AppService } from '@/infrastructures/api-service'
import { SupportedChains } from './types'

export interface CollectionInfo {
  name: string
  image_url: string

  marketCap: number
  volume: number
  floorPrice: number
  aiFloorPrice: number
  holder: number
  accuracy: number
}

export type QueryCollectionDetailParams = {
  collectionId?: string | number
  chain?: SupportedChains
}

export const useQueryCollectionDetail = (query?: QueryCollectionDetailParams) => {
  return useQuery<CollectionInfo>(
    ['COLLECTION_DETAIL', query],
    () => {
      if (!query?.collectionId || !query.chain) return undefined

      return AppService.post('/collection/statistics', query) as any
    },
    {
      keepPreviousData: true
    }
  )
}
