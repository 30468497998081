import { useLocation } from 'react-router-dom'

export function useLocationQuery<Result = string>(key: string): Result | undefined {
  return (new URLSearchParams(useLocation().search).get(key) as unknown as  Result) ?? undefined
}

type UseLocationQueriesProps = Array<{
  key: string
  defaultValue?: string
}>

export const useLocationQueries = (props: UseLocationQueriesProps): Array<string> => {
  return props.map(({ key, defaultValue }) => {
    return new URLSearchParams(useLocation().search).get(key) ?? defaultValue ?? ''
  })
}
