import { styled, Table } from '@mui/material'

type TransactionType = 'PlaceBid' | 'Listing'

const getTagStyleByType = ({ type }: any) => {
  switch (type) {
  case 'PlaceBid':
    return {
      borderColor: 'rgb(90,55,226)',
      // backgroundColor: 'rgba(30,27,108,0.44)'
    }

  case 'Listing':
    return {
      borderColor: 'rgb(0,255,171)',
      // backgroundColor: 'rgba(27,108,40,0.44)'
    }

  case 'CancelListing':
    return {
      borderColor: 'rgb(209, 81, 45)',
      // backgroundColor: 'rgba(232,162,50,0.24)'
    }

  default:
    return ''
  }
}

export const DataGridItem = styled('div')`
  border: 1px rgb(49, 64, 103) solid;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(12, 13, 33);
  border-radius: .5rem;
  height: 180px;
`

export const StyledTable = styled(Table)`
  width: 100%;

  .MuiTableCell-root {
    border-bottom: 1px rgba(62, 91, 164, 0.66) solid;
  }

  .-MuiTable-root ,.MuiTableCell-root {
    padding: 10px 14px;
  }
  
`

export const TransactionTag = styled('div')<{type?:string}>`
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  padding: 4px 10px;
  border-radius: 1rem;
  //background-color: rgba(23, 24, 33, 0.84);
  color: #eee;
  border-width: 2px;
  border-style: solid;

  ${getTagStyleByType}
`

export const HighlightTitle = styled('div')`
  font-size: 32px;
  background: linear-gradient(to top,  rgb(13,40,125) 50%, transparent 50%) no-repeat center;
  background-size: 100% 40%;
  color: #fff;
  width: fit-content;
  padding: 0 20px;

  margin-bottom: 16px;
`

export const NFTName = styled('div')`
  background: linear-gradient(to right, rgb(186, 230, 243), rgb(83, 197, 231)); // create bg gradient
  color: transparent; // hide text fill
  background-clip: text;
  font-size: 34px;
`

export const SearchWrapper = styled('div')`
  position: relative;
  border: 2px rgb(13, 40, 125) solid;
  background: rgba(24, 25, 44, 0.7);
  border-radius: 20px;
  min-width: 280px;
  height: 40px;

  input {
    border: none;
    width: 100%;
    background: transparent;
    padding: 0 12px;
    color: white;
    outline: none;
    height: 100%;
    overflow: hidden;
    font-weight: 100;
    transform-origin: 0 50%;
    transition: 275ms ease;

    &::placeholder {
      color: #cccccc;
    }
  }

  svg {
    position: absolute;
    color: white;
    top: 25%;
    right: 13px;
    width: 22px;
    height: 22px;
  }
`
