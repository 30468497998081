import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useQuerySupportedCollections } from '@/hooks/queries/useQuerySupportedCollections'
import { useLocation, useNavigate } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import _ from 'lodash'
import { Flex, Image, Skeleton, Text } from '@banksea-finance/ui-kit'
import { SearchWrapper, StyledTable } from '@/pages/collection/index.style'
import { SearchRounded } from '@mui/icons-material'
import { SolanaMarkSvg } from '@/components/svgs'
import { abbrNumber, imageProxyUrl } from '@/utils'
import { SupportedChains } from '@/hooks/queries/types'
import { PriceLabel } from '@/components/price-label'

export const HomePage: React.FC = () => {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(useLocation().search)

  const inputRef = useRef<HTMLInputElement>()
  const [total, setTotal] = useState(0)
  const [search, _setSearch] = useState('')
  const [current, setCurrent] = useState(() => +(searchParams.get('page') || 1))

  const setSearch = (val: string) => {
    _setSearch(prev => {
      if (prev !== val) setCurrent(1)

      return val
    })
  }

  const [chain, setChain] = useState<SupportedChains>((searchParams.get('chain') || 'Solana') as SupportedChains)

  const { data: collections } = useQuerySupportedCollections({ search, current, chain })

  useEffect(() => {
    searchParams.set('page', current.toString())
    searchParams.set('chain', chain)
    navigate(`?${searchParams.toString()}`, { replace: true })
  }, [current, chain])

  useEffect(() => {
    setSearch('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [chain, inputRef])

  useEffect(() => {
    collections && setTotal(collections.total)
  }, [collections, total])

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} mb={'16px'}>
        <Typography variant={'h4'}>Supported NFT Collections</Typography>

        <Flex ai={'center'}>
          <ToggleButtonGroup
            sx={{ mr: '12px' }}
            size={'medium'}
            value={chain}
            onChange={(event, value) => value && setChain(value)}
            exclusive
          >
            <ToggleButton value={'Solana'}>
              <Flex ai={'center'}>
                <SolanaMarkSvg height={'16px'} />
                <Flex ml={'4px'}>Solana</Flex>
              </Flex>
            </ToggleButton>
            <ToggleButton value={'Ethereum'}>
              <Flex ai={'center'}>
                <Image src={'https://ethereum.org/static/a183661dd70e0e5c70689a0ec95ef0ba/13c43/eth-diamond-purple.png'}
                  height={'20px'}
                />
                <Flex ml={'4px'}>Ethereum</Flex>
              </Flex>
            </ToggleButton>
          </ToggleButtonGroup>

          <SearchWrapper>
            <input
              ref={ref => (inputRef.current as any) = ref}
              className={'search-input'}
              placeholder={'Searching by collection name'}
              onChange={
                _.debounce(
                  ({ target }) => {
                    setSearch(target.value)
                    setCurrent(1)
                  },
                  500,
                  { trailing: true })
              }
            />
            <SearchRounded />
          </SearchWrapper>
        </Flex>
      </Box>

      <TableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow sx={{ '& > th': { color: grey['500'] } }}>
              <TableCell>Collection Name</TableCell>
              <TableCell align="center">Market Cap</TableCell>
              <TableCell align="center">7d Volume</TableCell>
              <TableCell align="center">Floor Price</TableCell>
              <TableCell align="right">Accuracy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              collections ? (
                collections.records?.map(row => (
                  <TableRow
                    key={row.name}
                    onClick={() => navigate(`/collection/${row.collectionId}?chain=${chain}`)}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      ':hover': { cursor: 'pointer' }
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Flex ai={'center'}>
                        <Image
                          src={imageProxyUrl(row.imageUrl, { width: 64, height: 64 })}
                          width={'42px'}
                          height={'42px'}
                          borderRadius={'.4rem'}
                          mr={'8px'}
                        />
                        <span>{row.name}</span>
                      </Flex>
                    </TableCell>
                    <TableCell align="center">
                      <PriceLabel value={abbrNumber(row.marketCap)} chain={chain} />
                    </TableCell>
                    <TableCell align="center">
                      <PriceLabel value={row.volume?.toFixed(2)} chain={chain} />
                    </TableCell>
                    <TableCell align="center">
                      <PriceLabel value={row.floorPrice?.toFixed(2)} chain={chain} />
                    </TableCell>
                    <TableCell align="right">
                      {row.accuracy ? `${(row.accuracy * 100).toFixed(2)}%` : '-'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                _.range(10).map((o, i) => (
                  <TableRow
                    key={`placeholder-${i}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      ':hover': { cursor: 'pointer' }
                    }}
                  >
                    {
                      _.range(5).map((_o, j) => (
                        <TableCell key={j}>
                          <Skeleton width={'100%'} height={'42px'} borderRadius={'21px'} />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </StyledTable>
      </TableContainer>

      {
        total ? (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={total}
            rowsPerPage={10}
            page={current - 1}
            onPageChange={(event, page) => setCurrent(page + 1)}
          />
        ) : (<></>)
      }
    </Box>
  )
}
