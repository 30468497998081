
import { useQuery } from '@tanstack/react-query'
import { AppService } from '@/infrastructures/api-service'
import { SupportedChains } from './types'

export type QueryCollectionValuationParams = {
  collectionId: string | number
  chain: SupportedChains
  startTime?: number
  endTime?: number
}

export interface CollectionValuation {
  timestamp: number
  transactionDate: string
  whiteListId: string
  nftName: string
  totalAmount: number
  aiValuation: number
  variance: number
}


export const useQueryCollectionValuation = (query: Partial<QueryCollectionValuationParams>) => {
  return useQuery<CollectionValuation[]>(
    ['COLLECTION_VALUATION', query],
    () => {
      if (!query.collectionId) return undefined

      return AppService.post('/collection/valuation', {
        ...query,
        startTime: query.startTime || 0,
        endTime: query.endTime || Date.now()
      }) as any
    }
  )
}
