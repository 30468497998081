import { useQuery } from '@tanstack/react-query'
import { AppService, BankseaApiPageQuery, BankseaApiPageResult } from '@/infrastructures/api-service'
import { SupportedChains } from './types'

export interface TokenActivity {
  tokenAddress: string
  imageUrl: string
  metaUriName: string
  holder: string
  transactionSignature: string
  transactionTime: number
  transactionProgram: string
  transactionPrice: string
  isListing: number
  listingSignature: string
  listingTime: number
  listingProgram: string
  listingPrice: string
  rank: number
  valuation: number
  accuracy: number
}

export type CollectionActivitiesQuery = BankseaApiPageQuery & {
  collectionId?: string,
  chain?: SupportedChains
  search?: string
}

export const useQueryCollectionActivities = (query: CollectionActivitiesQuery) => {
  return useQuery<BankseaApiPageResult<TokenActivity>>(
    ['COLLECTION_ACTIVITIES', query],
    () => {
      if (!query.chain || !query.collectionId) return undefined

      return AppService.post('/collection/items', query) as any
    },
  )
}
