import { AppService } from '@/infrastructures/api-service'
import { useQuery } from '@tanstack/react-query'

export interface TokenActivity {
  activities: Activity[]
  valuations: Valuation[]
  listings: Listing[]
  sales: Sale[]
  bids: any[]
  mape: any
}

export interface Activity {
  tokenMint: string
  metaUriImage: string
  metaUriName: string
  transactionType: string
  totalAmount?: number
  seller: string
  buyer: string
  transactionTime: number
}

export interface Valuation {
  valuation: number
  timestamp: number
}

export interface Listing {
  transactionType: string
  totalAmount: number
  transactionTime: number
}

export interface Sale {
  transactionType: string
  totalAmount: number
  transactionTime: number
}


export const useQueryTokenActivities = (token?: string) => {
  return useQuery<TokenActivity>(
    ['TOKEN_ACTIVITIES', token],
    () => {
      if (!token) return undefined

      return AppService.post('/token/activities', { token }) as any
    }
  )
}



