import { AppService } from '@/infrastructures/api-service'
import { useQuery } from '@tanstack/react-query'

export interface TokenBaseInfo {
  attributes: TokenAttribute[]
  rank: number
  token_name: string
  image_url: string
  rarity_score: number
}

export interface TokenAttribute {
  amount: number
  rarity: number
  value: string
  trait_type: string
}

export const useQueryTokenBaseInfo = (token?: string) => {
  return useQuery<TokenBaseInfo>(
    ['TOKEN_BASE_INFO', token],
    () => {
      if (!token) return undefined

      return AppService.post('/token/attributes', { token }) as any
    }
  )
}
