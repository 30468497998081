import React from 'react'
import './App.css'
import '../src/assets/fonts/font.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HomePage } from '@/pages/home'
import { CollectionPage } from '@/pages/collection'
import { TokenPage } from '@/pages/token'

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/collection/:collectionId'} element={<CollectionPage />} />
          <Route path={'/token/:tokenAddress'} element={<TokenPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
