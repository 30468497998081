import { AppService, BankseaApiPageQuery, BankseaApiPageResult } from '@/infrastructures/api-service'
import { useQuery } from '@tanstack/react-query'
import { SupportedChains } from './types'

export interface CollectionOverview {
  imageUrl: string
  collectionId: string
  marketCap: number
  volume: number
  floorPrice: number
  accuracy: number
  name: string
}

export type SupportedCollectionsQuery = BankseaApiPageQuery & {
  search?: string
  chain: SupportedChains
}

export const useQuerySupportedCollections = (query: SupportedCollectionsQuery) => {
  return useQuery<BankseaApiPageResult<CollectionOverview>>(
    ['SUPPORTED_COLLECTIONS', query],
    () => {
      return AppService.post('/collection/page', { ...query, size: 10 }) as any
    }
  )
}
